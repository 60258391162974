import { api } from ":mods";
import { CONST, model } from "..";
import { AShowcase } from "../../model";
export async function listPost(stage: number | string, data: model.PostShowcase) {
  return api
    .postAuth(CONST.ENDPOINTS.admin.list_showcases(stage), data, {
      headers: {
        // "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      return res.data.data.show_cases as AShowcase;
    });
}
